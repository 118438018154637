.App {
  display: flex;
  height: 100vh;
}
.circle2 {
  /* width: 100px;
  height: 100px;
  background: lightblue;
  position: absolute; */
  position: absolute;
  border-radius: 50%;
  right: -65px;
  top: -121px;
  background: linear-gradient(
    142.41deg,
    rgba(60, 35, 219, 0.45) -23.34%,
    rgba(0, 219, 222, 0.88) 38.55%
  );
  width: 193px;
  height: 196px;
}
.circle1 {
  /* width: 100px;
  height: 100px;
  background: lightblue;
  position: absolute; */
  position: absolute;
  border-radius: 50%;
  right: -117px;
  top: -70px;
  background: linear-gradient(
    123.67deg,
    #e1488d 26.5%,
    rgba(163, 35, 210, 0.63) 89.03%
  );
  width: 193px;
  height: 196px;
}

::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.leftSideMessage {
  max-width: 60%;
  white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {
  .addLogoIcon {
    background: linear-gradient(to right, #e25192, #cc45ae) !important;
  }
  .addLogoIcon h1 {
    color: white !important;
  }
}
