@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bio";
  src: local("Bio"), url("./fonts/Bio/Biome W04 Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Earth Orbiter";
  src: local("Earth Orbiter"),
    url("./fonts/Earth Orbiter Font/earthorbiter.ttf") format("truetype");
}

@font-face {
  font-family: "Earth Orbiter Bold";
  src: local("Earth Orbiter Bold"),
    url("./fonts/Earth Orbiter Font/earthorbiterbold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Urbanist";
  src: local("Urbanist"),
    url("./fonts/Urbanist/Urbanist-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
