.sidebar__link {
  padding: 10px 0;
  margin-bottom: 5px;
}

.sidebar__link:hover {
  color: #e1488d !important;
}
.sidebar__link--active {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-radius: 8px;
}
